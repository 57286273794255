<script>
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";
import { SuawInputGroup, SuawHeading, SuawThumbnail, SuawDivider } from "@/components";
import image1 from "../../../public/img/resources/organizer/listing-your-online-event-image-1.png";
import image2 from "../../../public/img/resources/organizer/listing-your-online-event-image-2.png";
import image3 from "../../../public/img/resources/organizer/listing-your-online-event-image-3.png";
import image4 from "../../../public/img/resources/organizer/listing-your-online-event-image-4.png";
export default {
  name: "ListingYourOnlineEvent",
  components: { ResourceFrame, SuawInputGroup, SuawHeading, SuawThumbnail, SuawDivider },
  data() {
    return {
      image1,
      image2,
      image3,
      image4
    }
  }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="organizer">
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-organizer-resource-heading">Listing Your <b>Online Event</b></h1>

      <p class="suaw-paragraph">
        If you are interested in listing your online event for a global audience, you'll start by creating a listing on the shutupwrite.com website, and then duplicate it on Meetup. This will help you reach more writers all over the world.
      </p>

      <p class="suaw-paragraph">If you are interested in <a href="#local-chapter">listing your online event for your local chapter</a>, you will only list it on Meetup.</p>

      <h2 class="suaw-organizer-resource-subtitle">Listing Online Events for a Global Audience</h2>

      <h2 class="suaw-organizer-resource-subtitle">Shut Up & Write! Website</h2>
      <p class="suaw-paragraph">
        First, you'll list your event on the Shut Up & Write! website. Your online event will show up in our <router-link :to="{ name: 'OnlineEvents' }">Online Events directory</router-link>. Here's how to create a listing:
      </p>

      <ol class="suaw-organizer-resource-steps">
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">
            <router-link :to="{ name: 'LogIn' }">Log into</router-link> the Shut Up & Write! website. Our team will promote your account to the Organizer access level so you
            can set up an event. If you haven't been promoted on our website, let us know.
          </p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Go to our <router-link :to="{ name: 'OnlineEventDiscovery' }">Online Events directory</router-link>.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Click the <b>Create Event Series</b> button.</p>
        </li>
        <SuawThumbnail :content="image1" />
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Fill out the Start Date, Start Time, Duration, and Frequency</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">You should see your account name already listed in the Organizer section. Click Next.</p>
        </li>
        <SuawThumbnail :content="image2" />
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">
            Fill out your video conferencing information. This is where to add your link to your Zoom, Google Meet, Jitsi, or other online web-conferencing room. Click Next.
          </p>
        </li>
        <SuawThumbnail :content="image3" />

        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Change your Title to "Shut Up & Write! with [YOUR NAME]"</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Upload a Series Image</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Add an RSVP limit if you need to limit how many people can attend. Up to 15 people can be a good number for online events.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Click "Publish Series"</p>
        </li>
        <SuawThumbnail :content="image4" />
      </ol>

      <p class="suaw-paragraph"><b>Make note of the URL of your event series page.</b></p>
      <p class="suaw-paragraph">Bookmark it for later, since you will be returning to this page to maintain your event listing.</p>

      <h2 class="suaw-organizer-resource-subtitle">Meetup</h2>
      <p class="suaw-paragraph">
        Shut Up & Write! funds a <a href="https://www.meetup.com/pro/shut-up-write/" target="_blank">Meetup Pro</a> account for our entire network of organizers. That means the cost of your Meetup listing is on us! By listing events on the Meetup network, we help our organizers connect with writers all over the world.
      </p>

      <p class="suaw-paragraph">
        <b>Chapters: </b>Meetup organizes events into groups by location. At Shut Up & Write!, we call these “chapters.” Because you’re hosting online for a global audience, our team will assign your event to our <a href="https://www.meetup.com/shutupandwriteonlineevents/" target="_blank">online events chapter</a>.
      </p>

      <SuawHeading level="4" content="Setup" />
      <ol class="suaw-organizer-resource-steps">
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Copy the URL of the <b>event link you set up on shutupwrite.com</b> on your browser.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">
            Open up the draft of your Meetup.com event series page. <b>A Community Team member will be sending a link to your event template via <a href="mailto:questions@shutupwrite.com">questions@shutupwrite.com</a>.</b>
          </p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Click the Edit button at the top of your screen.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Change the fields below:</p>
          <ul class="suaw-organizer-resource-guide__list">
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Date</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Time</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Duration</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Link (for your online web-conferencing room, like a Zoom link, Jitsi link, or similar)</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Repeat event: make sure the correct days of the week are selected for automatically recurring events</p>
            </li>
          </ul>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Location: Paste the event link that you copied at the beginning of this section.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Check that all of your event details are correct, and then click the red Publish button at the bottom of your screen.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">
            <b>Make note of the URL of your Meetup event page.</b> Bookmark it, if possible, since you will be returning to this page to maintain your event listing.
          </p>
        </li>
      </ol>

      <p class="suaw-paragraph">Congrats! Your writing event is now listed on Meetup.com.</p>
      <SuawDivider />

      <h2 id="local-chapter" class="suaw-organizer-resource-subtitle">Listing Online Events for Your Local Chapter</h2>

      <h2 class="suaw-organizer-resource-subtitle">Meetup</h2>
      <p class="suaw-paragraph">
        Shut Up & Write! funds a <a href="https://www.meetup.com/pro/shut-up-write/" target="_blank">Meetup Pro</a> account for our entire network of organizers. That means the cost of your Meetup listing is on us! By listing events on the Meetup network, we help our organizers connect with writers all over the world.
      </p>

      <p class="suaw-paragraph">
        <b>Chapters: </b>Meetup organizes events into groups by location. At Shut Up & Write!, we call these "chapters." Because you're hosting online for your local writers, our team will assign your event to your local chapter.
      </p>

      <SuawHeading level="4" content="Setup" />
      <ol class="suaw-organizer-resource-steps">
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">
            Open up the draft of your Meetup.com event series page. <b>A Community Team member will be sending a link to your event template via <a href="mailto:questions@shutupwrite.com">questions@shutupwrite.com</a>.</b>
          </p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Click the Edit button at the top of your screen.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Change the fields below:</p>
          <ul class="suaw-organizer-resource-guide__list">
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Date</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Time</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Duration</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Link (for your online web-conferencing room, like a Zoom link, Jitsi link, or similar)</p>
            </li>
            <li class="suaw-organizer-resource-guide__item">
              <p class="suaw-paragraph">Repeat event: make sure the correct days of the week are selected for automatically recurring events</p>
            </li>
          </ul>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">Check that all of your event details are correct, and then click the red Publish button at the bottom of your screen.</p>
        </li>
        <li class="suaw-organizer-resource-steps__item">
          <p class="suaw-paragraph">
            <b>Make note of the URL of your Meetup event page.</b> Bookmark it, if possible, since you will be returning to this page to maintain your event listing.
          </p>
        </li>
      </ol>

      <p class="suaw-paragraph">Congrats! Your writing event is now listed on Meetup.com.</p>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-paragraph {
  color: var(--sem-color-text-medium);
  line-height: var(--p-line-height);
  font-size: var(--p-font-size);
  font-weight: var(--p-font-weight);
}

.suaw-organizer-resource-intro {
  color: var(--sem-color-neutral-dark);
}

.suaw-organizer-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.suaw-organizer-resource-image {
  max-width: 100%;
  height: auto;
  margin: 16px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suaw-organizer-resource-steps {
  list-style: decimal;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: var(--sem-color-text-medium);
  font-family: Roboto;

  &__item {
    padding-left: 8px;
  }
}

.suaw-organizer-resource-substeps {
  list-style: lower-alpha;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  color: var(--sem-color-text-medium);
  font-family: Roboto;

  &__item {
    padding-left: 8px;
  }
}

.suaw-organizer-resource-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
